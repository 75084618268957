.App {
  text-align: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #eefbff;
  cursor: url(./assets/cursor.png), auto;
}

a {
  cursor: url(./assets/hand_cursor.png), pointer;
}

.page-container {
  display: flex;
}

#stacked-col {
  flex-direction: column;
}

.content-wrap {
  flex: 2;
}
