.home {
  color: red;
  width: 2.9rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.home:hover {
  color: rgba(255, 0, 0, 0.514);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.work {
  color: blue;
  width: 2.5rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.work:hover {
  color: rgba(0, 0, 255, 0.521);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.exp {
  color: rgb(204, 0, 255);
  width: 5.6rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.exp:hover {
  color: rgba(204, 0, 255, 0.5);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.con {
  color: #009e00;
  width: 3.9rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.con:hover {
  color: #009e0070;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.navbar {
  display: flex;
  flex-direction: row;
  margin-top: 1.6rem;
  float: right;
  margin-right: 4%;
}

.cheselogo {
  float: left;
  margin-left: 4%;
  margin-top: 1rem;
  width: 9rem;
}

.cheselogo:hover {
  opacity: 0.8;
}

/* .chesehover {
  float: left;
  margin-left: 4%;
  margin-top: 1rem;
  width: 6rem;
}

.chesehover:hover {
  opacity: 0.8;
} */

.moveArrow {
  animation-name: mousemove;
  animation-duration: 8s;
  animation-iteration-count: 1;
}

@keyframes mousemove {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(calc(100vw - 130px));
  }
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.socials > div {
  margin: 1rem;
}

.youtube {
  width: 10rem;
  animation: ytfloat 1.5s ease-in-out infinite;
}

.youtube:hover {
  opacity: 0.8;
  animation-duration: 2s;
}

.github {
  width: 9rem;
  animation: ghfloat 1.4s ease-in-out infinite;
}

.github:hover {
  opacity: 0.8;
  animation-duration: 2s;
}

.gmail {
  width: 9rem;
  animation: ghfloat 1.4s ease-in-out infinite;
}

.gmail:hover {
  opacity: 0.8;
  animation-duration: 2s;
}

.mail {
  width: 9rem;
  animation: mailfloat 1.5s ease-in-out infinite;
}

.mail:hover {
  opacity: 0.8;
  animation-duration: 2s;
}

.inlinked {
  width: 9rem;
  animation: ilfloat 1.3s ease-in-out infinite;
}

.inlinked:hover {
  opacity: 0.8;
  animation-duration: 2s;
}

.inlinked-contact {
  width: 9rem;
  animation: ilfloat 1.3s ease-in-out infinite;
}

.inlinked-contact:hover {
  opacity: 0.8;
  animation-duration: 2s;
}

.navitemshome {
  text-decoration: none;
  height: 0px;
  /* margin-right: 10px; */
}

.navitemswork {
  text-decoration: none;
  height: 0px;
  /* margin-right: 10px; */
}

.navitemsexp {
  text-decoration: none;
  height: 0px;
  /* margin-right: 10px; */
}

.navitemscon {
  text-decoration: none;
  height: 0px;
}

@keyframes mailfloat {
  0% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(px);
  }
  50% {
    filter: drop-shadow(0px 25px 8px #b1b1b1);
    transform: translatey(-12px);
  }
  100% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
}

@keyframes ytfloat {
  0% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 25px 8px #b1b1b1);
    transform: translatey(-12px);
  }
  100% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
}

@keyframes ghfloat {
  0% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 25px 8px #b1b1b1);
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
}

@keyframes ilfloat {
  0% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 25px 8px #b1b1b1);
    transform: translatey(-12px);
  }
  100% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
}

@keyframes float_msg {
  0% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 25px 8px #b1b1b1);
    transform: translatey(-5px);
  }
  100% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0px 0px 40px 5px #eefbff;
  }
  100% {
    box-shadow: 0px 0px 40px 5px #000000;
  }
}

@keyframes glowoff {
  0% {
    box-shadow: 0px 0px 40px 5px #000000;
  }
  100% {
    box-shadow: 0px 0px 40px 5px #eefbff;
  }
}

@keyframes msgappear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mouse_msg {
  width: 15rem;
  position: fixed;
  right: 1rem;
  bottom: 1.5rem;
  animation: float_msg 1.5s ease-in-out infinite, msgappear 3s ease-in-out 1;
}

.mouse_msg:hover {
  opacity: 0.8;
}

.wordglow:hover {
  animation: glow 1s ease-in-out 1;
  animation-fill-mode: forwards;
}

.wordglow:not(:hover) {
  animation: glowoff 1s ease-in-out 1;
  animation-fill-mode: forwards;
}

.text-stuff {
  text-align: right;
}

.pic-stuff {
  float: left;
}

.container {
  margin: 150px auto;
  margin-top: 5rem;
  width: 60%;
  display: flex;
}

.beautiful {
  filter: drop-shadow(0px 0px 2px #635c1b);
}

@keyframes mnfloat {
  0% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 25px 8px #b1b1b1);
    transform: translatey(-12px);
  }
  100% {
    filter: drop-shadow(0px 10px 4px #979797);
    transform: translatey(0px);
  }
}

@keyframes mnrotate {
  0% {
    transform: rotate(0deg);
    filter: drop-shadow(0px 10px 4px #979797);
  }
  10% {
    filter: drop-shadow(0px 5px 4px #979797);
  }
  20% {
    filter: drop-shadow(0px 0px 4px #979797);
  }
  30% {
    filter: drop-shadow(0px -5px 4px #979797);
  }
  40% {
    filter: drop-shadow(0px -10px 4px #979797);
  }
  50% {
    filter: drop-shadow(0px -10px 4px #979797);
  }
  60% {
    filter: drop-shadow(0px -10px 4px #979797);
  }
  70% {
    filter: drop-shadow(0px -5px 4px #979797);
  }
  80% {
    filter: drop-shadow(0px 0px 4px #979797);
  }
  90% {
    filter: drop-shadow(0px 5px 4px #979797);
  }
  100% {
    transform: rotate(360deg);
    filter: drop-shadow(0px 10px 4px #979797);
  }
}

.moneris {
  width: 10rem;
  animation: mnfloat 1.5s ease-in-out infinite;
}

.moneris:hover {
  opacity: 0.8;
  animation: mnrotate 1.5s linear infinite;
}

.pin {
  width: 10rem;
  animation: mnfloat 1.5s ease-in-out infinite;
}

.pin:hover {
  opacity: 0.8;
  animation: mnrotate 1.5s linear infinite;
}

.batmobile {
  width: 20rem;
  animation: mnfloat 1.5s ease-in-out infinite;
}

.batmobile:hover {
  opacity: 0.8;
  animation: mnrotate 1.5s linear infinite;
}

.autocar {
  width: 18rem;
  animation: mnfloat 1.5s ease-in-out infinite;
}

.autocar:hover {
  opacity: 0.8;
  animation: mnrotate 1.5s linear infinite;
}

.wrapper::-webkit-scrollbar {
  width: 10px;
}

.wrapper::-webkit-scrollbar-track {
  background: none;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  opacity: 0.2;
}

#wrapper-work::-webkit-scrollbar-thumb {
  background: url("https://i.ibb.co/VN9S0tr/scrollbar.png");
}

#wrapper-exp::-webkit-scrollbar-thumb {
  background: url("https://i.ibb.co/bWvF0rW/exp-scroll.png");
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.wrapper {
  height: calc(100vh - 194px);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  visibility: hidden;
}

.main-content,
.wrapper:hover,
.wrapper:focus {
  visibility: visible;
}

.wrapper:hover {
  overflow-y: auto;
}

.main-content {
  margin-top: 70px;
  flex: 0 0 auto;
}

.scrollbox_delayed {
  transition: visibility 0.2s;
}

.scrollbox_delayed:hover {
  transition: visibility 0s 0.2s;
}

@keyframes arrowfloat {
  0% {
    filter: drop-shadow(0px 0px 4px #b3b3b3);
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 0px 6px #888888);
    transform: translatey(-6px);
  }
  100% {
    filter: drop-shadow(0px 0px 4px #b3b3b3);
    transform: translatey(0px);
  }
}

.arrowfloat {
  animation: arrowfloat 1s ease-in-out infinite;
  width: 2.5rem;
  margin-top: -5rem;
}

.arrowfloat:hover {
  opacity: 0.8;
}

.header {
  font-size: 2rem;
  filter: drop-shadow(0px 6px 6px #dfdfdf);
}

.picofme {
  width: 20rem;
}

.contact-socials {
  margin-top: 5rem;
  margin-left: 19.2rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1447px) {
  .gmail {
    width: 7rem;
    margin-right: -1rem;
  }
  .mail {
    width: 7rem;
    margin-right: -1rem;
  }
  .inlinked-contact {
    width: 7rem;
  }
}

@media screen and (max-width: 1280px) {
  .gmail {
    width: 6rem;
    margin-right: -1rem;
  }
  .mail {
    width: 6rem;
    margin-right: -1rem;
  }
  .inlinked-contact {
    width: 6rem;
  }
}

@media screen and (max-width: 1195px) {
  .gmail {
    width: 5rem;
    margin-right: -1rem;
  }
  .mail {
    width: 5rem;
    margin-right: -1rem;
  }
  .inlinked-contact {
    width: 5rem;
  }
}

@media screen and (max-width: 1050px) {
  .gmail {
    width: 4rem;
    margin-right: -1.5rem;
  }
  .mail {
    width: 4rem;
    margin-right: -1.5rem;
  }
  .inlinked-contact {
    width: 4rem;
  }
}

@media screen and (max-width: 950px) {
  .description {
    font-size: 1.2rem;
  }
  .header {
    font-size: 1.75rem;
  }
  .arrowfloat {
    width: 2.2rem;
  }
  .moneris {
    width: 8rem;
  }
  .batmobile {
    width: 15rem;
  }
  .autocar {
    width: 14rem;
  }
  .pin {
    width: 8rem;
  }
  .mouse_msg {
    width: 13rem;
  }
  .picofme {
    width: 18rem;
  }
  .inlinked {
    width: 7rem;
  }
  .github {
    width: 7rem;
  }
  .youtube {
    width: 7rem;
  }
  .mail {
    width: 4rem;
  }
  .contact-socials {
    margin-left: 5rem;
  }
}

@media screen and (max-width: 800px) {
  .description {
    font-size: 1rem;
  }
  .header {
    font-size: 1.5rem;
  }
  .batmobile {
    width: 11rem;
  }
  .autocar {
    width: 10rem;
  }
  .mouse_msg {
    width: 12rem;
  }
  .picofme {
    width: 16rem;
  }
  .inlinked {
    width: 6rem;
  }
  .github {
    width: 6rem;
  }
  .youtube {
    width: 6rem;
  }
  .mail {
    width: 4rem;
  }
  .contact-socials {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 700px) {
  .contact-socials {
    margin-left: 0;
  }
  .batmobile {
    width: 9rem;
  }
  .autocar {
    width: 8rem;
  }
}

@media screen and (max-width: 575px) {
  .description {
    font-size: 0.8rem;
  }
  .header {
    font-size: 1.3rem;
  }
  .moneris {
    width: 6rem;
  }
  .batmobile {
    width: 6rem;
  }
  .autocar {
    width: 6rem;
  }
  .pin {
    width: 6rem;
  }
  .mouse_msg {
    width: 10rem;
  }
  .picofme {
    width: 12rem;
  }
  .inlinked {
    width: 5rem;
  }
  .github {
    width: 5rem;
  }
  .youtube {
    width: 5rem;
  }
  .gmail {
    width: 2rem;
    margin-right: -1.5rem;
  }
  .mail {
    width: 2rem;
    margin-right: -1.5rem;
  }
  .inlinked-contact {
    width: 2rem;
  }
  .contact-socials {
    margin-left: 3.5rem;
  }
}

@media screen and (max-width: 450px) {
  .cheselogo {
    width: 4rem;
  }
  .navitemshome {
    font-size: 0.75rem;
    margin-right: -0.65rem;
  }
  .navitemswork {
    font-size: 0.75rem;
    margin-right: -1rem;
  }
  .navitemsexp {
    font-size: 0.75rem;
    margin-right: -1.2rem;
  }
  .navitemscon {
    font-size: 0.75rem;
  }
  .picofme {
    width: 10rem;
    margin-top: 2rem;
  }
  .contact-socials {
    margin-left: 1.75rem;
  }
}
